import { Model, attr } from "redux-orm";
import { UPSERT_AUTHAPPS, RESET_AUTHAPPS } from "../constants";

class AuthApps extends Model {
  static reducer(action, AuthApps) {
    switch (action.type) {
      case UPSERT_AUTHAPPS:
        action.payload?.forEach((app) => {
          AuthApps.upsert(app);
        });
        break;
      case RESET_AUTHAPPS:
        AuthApps.delete();
        break;
      default:
        break;
    }
  }
}
AuthApps.modelName = "AuthApps";

AuthApps.options = {
  idAttribute: "defappsid", // default idd
};
// attributen
AuthApps.fields = {
  $id: attr(),
  defappsid: attr(),
  code: attr(),
  description: attr(),
};

export default AuthApps;
