import { ERROR, LOGOUT } from "../../constants";
import Axios from "axios";
import { getURL, getToken, local_frontend_ip_address } from "../../../utils";

// // reset redux store
export const resetStore = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};

// geen keuze gemaakt in de licenties of licentie versies
export const choiceIsEmpty = (message) => (dispatch) => {
  dispatch({
    type: ERROR,
    payload: message,
  });
};

// haal de apps url's
export const getClientUrl = async (selectedapp, tokens) => {
  const app = selectedapp.toUpperCase();
  const { access_token, refresh_token } = tokens;

  try {
    const response = await Axios.get(`${getURL()}baseurl`, {
      params: { app: app },
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    // voor local testen
    if (!process.env.REACT_APP_API_URL) {
      if (response.data.frontend_url !== "") {
        switch (app) {
          case "ELM":
            return `${response.data.frontend_url}login/${access_token}/${refresh_token}/${sessionStorage.getItem(
              "app_user_license"
            )}`;
          case "BAC":
            return `${
              response.data.frontend_url
            }login/${access_token}/${refresh_token}/-all/${sessionStorage.getItem(
              "app_user_license"
            )}/${sessionStorage.getItem("app_user_version")}`;
          default:
            return null;
        }
      } else {
        switch (app) {
          // licentiebeheer url
          case "ELM":
            return `http://${local_frontend_ip_address}:3002/login/${access_token}/${refresh_token}/${sessionStorage.getItem(
              "app_user_license"
            )}`;
          // lmsbackoffice url
          case "BAC":
            return `http://${local_frontend_ip_address}:3001/login/${access_token}/${refresh_token}/-all/${sessionStorage.getItem(
              "app_user_license"
            )}/${sessionStorage.getItem("app_user_version")}`;
          default:
            return null;
        }
      }
    }
    // op aws
    else {
      switch (app) {
        case "ELM":
          return `${response.data.frontend_url}login/${access_token}/${refresh_token}/${sessionStorage.getItem(
            "app_user_license"
          )}`;
        case "BAC":
          return `${
            response.data.frontend_url
          }login/${access_token}/${refresh_token}/-all/${sessionStorage.getItem(
            "app_user_license"
          )}/${sessionStorage.getItem("app_user_version")}`;
        default:
          return null;
      }
    }
  } catch (error) {
    // toon foutmelding
  }
};
