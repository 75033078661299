import { useDispatch } from "react-redux";
import { useAuth } from "./AuthProvider";
import axios from "axios";
import { getURL } from "../utils";
import { resetStore } from "../redux/actions";

const useLogout = () => {
  const { auth } = useAuth();
  const dispatch = useDispatch();
  const clearStorage = () => {
    dispatch(resetStore);
    // clear localforage and sessionStorage
    sessionStorage.clear();
    // setAuth();
    //redirect gebruiker naar login
    window.location.href = `/`;
  };

  const logout = async () => {
    try {
      await axios.post(
        `${getURL()}logout`,
        {},
        {
          headers: { Authorization: `Bearer ${auth.access_token}` },
        }
      );

      clearStorage();
    } catch (error) {
      clearStorage();
    }
  };

  return logout;
};
export default useLogout;
