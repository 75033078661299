import { UPSERT_AUTHVERSIONS } from "../../constants";
import { handleErrors } from "../../../utils";
import { handleAppLogin } from "./apps";
import apiService from "../../../api/apiService";

// auth versions
export const getLicenseVersions = (id, app, history,cntxt) => async (dispatch) => {
  try {
    const response = await apiService.get(`auth_versions`, {
      defappsid: Number(id),
    });

    dispatch({
      type: UPSERT_AUTHVERSIONS,
      payload: response.data.value,
    });

    // sla appid in sessionstorage
    sessionStorage.setItem("selected_app", id);
    // als er alleen maar een versie is login in app
    if (Object.keys(response.data.value).length === 1) {
      dispatch(
        handleAppLogin({
          licenses: 1,
          history: history,
          defappsid: id,
          app: app,
          licversionsid: response.data.value[0].licversionsid,
        },cntxt)
      );
    }
    // als er meerdere versie aanwezig zijn, redirect naat keuze versie
    else {
      if (!window.location.pathname.includes("licenses/versions")) {
        // als de default licentie is al gevuld vanuit de url deze stap ook overslaan en direct op de licentie inloggen anders redirect naar d keuzelicentie pagina
        if (sessionStorage.getItem("app_user_version")) {
          dispatch(
            handleAppLogin({
              licenses: 1,
              history: history,
              defappsid: id,
              app: app,
              licversionsid: Number(sessionStorage.getItem("app_user_version")),
            },cntxt)
          );
        } else {
          history.push(
            `/selection/portal/app/${app.toLowerCase()}/licenses/versions`
          );
        }
      }
    }
  } catch (error) {
    // toon foutmelding
    dispatch(handleErrors(error));
  }
};
