import { createSelector } from "redux-orm";

import scheme from "../models/orm";

// standard auth apps
export const defaultApps = createSelector(scheme.AuthApps);

// gefilterde auth apps
export const allApps = createSelector(scheme, (orm) => {
  return orm.AuthApps.all()
    .toModelArray()
    .map(({ $id, defappsid, code, description }) => ({
      $id,
      defappsid,
      code,
      description,
    }));
});
