import { ORM } from "redux-orm";
import Users from "./userModel";
import Profiles from "./profileModel";
import ProfileSettings from "./profileSettingsModel";
import Versions from "./versionModel";
import Menu from "./headerMenuModel";
import Session from "./sessionModel";
import AuthVersions from "./authVersionsModel";
import AuthLicenses from "./authLicensesModel";
import AuthApps from "./authAppsModel";
import AmountLicenseUsers from "./licenseusersModel";
import Integrations from "./integrationsModel";

const orm = new ORM({
  stateSelector: (state) => state.scheme,
});
orm.register(
  Users,
  Profiles,
  ProfileSettings,
  Versions,
  Menu,
  Session,
  AuthVersions,
  AuthLicenses,
  AuthApps,
  AmountLicenseUsers,
  Integrations
);

export default orm;
