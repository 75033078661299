import * as types from '../constants';

//zet de default theme 0 default andere nummer indien meer thema aanwezig is
export default function reducer(state={ currentTheme: 0 }, action) {
  switch (action.type) {

    case types.SET_THEME:
      return {
        ...state,
        currentTheme: action.payload
      }

    default:
      return state
  }
}
