import styled from "styled-components";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import { Button as MuiButton, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { useTranslation } from "react-i18next";
import { useAuth } from "./AuthProvider";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

function Page404() {
  const { t } = useTranslation();
  const { auth } = useAuth();
  return (
    <Wrapper>
      <Helmet title="404" />
      <img
        src="/static/logo-evo-it-404-scala-bold-grijs.png"
        className="App-logo"
        alt="404-logo"
      />

      <Typography
        component="h1"
        variant="h2"
        align="center"
        gutterBottom
        color="primary"
      >
        {t("page404Component.title")}
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        {t("page404Component.subtitle")}
      </Typography>

      {/* geen token aanwezig redirect naar login anders naar de versie */}

      {auth.access_token ? (
        <Button
          component={Link}
          to={"/versions"}
          variant="contained"
          color="secondary"
          mt={2}
        >
          {t("page404Component.back")}
        </Button>
      ) : (
        <Button
          component={Link}
          to="/"
          variant="contained"
          color="secondary"
          mt={2}
        >
          {t("page404Component.back")}
        </Button>
      )}
    </Wrapper>
  );
}

export default Page404;
