import {
  Grid,
  Typography,
  DialogTitle,
  Button,
  DialogActions,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

function RenewSession() {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();
  const history = useHistory();
  const handleRenew = () => {
    setOpen(false);
    history.push("/");
  };

  return (
    <Dialog id="sessionDialog" maxWidth="sm" open={open}>
      <DialogTitle>{t("sessionComponent.renewTitle")}</DialogTitle>
      <DialogContent>
        <Grid container alignItems="center">
          <Grid item xs={1}>
            <Info color="primary" fontSize="large" />
          </Grid>
          <Grid item xs={11}>
            <Typography>{t("sessionComponent.renewMessage")}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleRenew()}
        >
          {t("loginComponent.loginButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default RenewSession;
