import { DELETE_USER, UPDATE_USER, SUCCESS, UPSERT_USER } from "../constants";
import { handleErrors } from "../../utils";
import translate from "i18next";
import apiService from "../../api/apiService";

// haal alle gebruikers op
export const fetchUsers = () => async (dispatch) => {
  try {
    const response = await apiService.get(`appmanagement/users`, {
      Top: 5000,
    });

    dispatch({
      type: UPSERT_USER,
      payload: response.data,
    });
  } catch (error) {
    // toon foutmelding
    dispatch(handleErrors(error));
  }
};

// maak nieuwe gebruiker aan
export const createUser = (user) => async (dispatch) => {
  try {
    const createResponse = await apiService.post(`appmanagement/users`, user);
    dispatch(fetchUsers());
    dispatch({
      type: SUCCESS,
      payload:
        translate.t("userComponent.messages.create") +
        ` - ${createResponse.data.value}`,
    });
  } catch (error) {
    // toon foutmelding
    dispatch(handleErrors(error));
  }
};

// gebruiker wijzigen
export const updateUser = (user) => async (dispatch) => {
  try {
    const updateResponse = await apiService.post(`appmanagement/users`, user);

    dispatch({
      type: UPDATE_USER,
      payload: user,
    });

    dispatch({
      type: SUCCESS,
      payload:
        translate.t("userComponent.messages.update") +
        ` - ${updateResponse.data.value}`,
    });
  } catch (error) {
    // toon foutmelding
    dispatch(handleErrors(error));
  }
};

// verwijderd gebruiker
export const deleteUser = (id) => async (dispatch) => {
  try {
    await apiService.delete(`appmanagement/users`, {
      usersid: id,
    });

    // verwijderd alleen de geselecteerde gebruiker in de orm
    dispatch({
      type: DELETE_USER,
      payload: id,
    });
    dispatch({
      type: SUCCESS,
      payload: translate.t("userComponent.messages.delete") + ` - ${id}`,
    });
  } catch (error) {
    // toon foutmelding
    dispatch(handleErrors(error));
  }
};
