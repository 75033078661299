import async from "../components/standardComponents/Async";
import { Unlock, Folder, Users, Link } from "react-feather";

const Login = async(() => import("../auth/Login"));
const integrations = async(() => import("../components/integration"));
const profile = async(() => import("../components/profiles"));
const version = async(() => import("../components/versions"));
const user = async(() => import("../components/users"));
const appsPortal = async(() => import("../auth/AppsPortal"));
const versionSelection = async(() => import("../auth/VersionSelection"));
const licenseSelection = async(() => import("../auth/LicenseSelection"));
const verifyAccount = async(() => import("../auth/VerifyAccount"));
const requestResetPassword = async(() =>
  import("../auth/RequestResetPassword")
);
const resetPassword = async(() => import("../auth/ResetPassword"));
const validate = async(() => import("../auth/2FactorAuth/TwoFactorAuth"));
const renewsession = async(() => import("../auth/RenewSession"));

//Sidenav componenten
const LoginRoutes = {
  id: "login",
  path: ["/", "/app/:appcode", "/app/:appcode/:licensesid/:licversionsid"],
  name: "Login",
  containsHome: true,
  component: Login,
  children: null,
};

const ResetPasswordRoutes = {
  id: "requestreset",
  path: "/forgot_my_password",
  name: "RequestReset",
  component: requestResetPassword,
  children: null,
};
const TwoFactorRoutes = {
  id: "twofactorauth",
  path: "/validate",
  name: "validate",
  component: validate,
  children: null,
};
const ValidateRoutes = {
  id: "verify",
  path: "/verifyuser/:Id",
  name: "Verify",
  component: verifyAccount,
  children: null,
};

const ResetRoutes = {
  id: "resetpassword",
  path: "/resetpassword/:Id",
  name: "ResetPassword",
  component: resetPassword,
  children: null,
};
const RequestRoutes = {
  id: "requestpassword",
  path: "/requestpassword/:Id",
  name: "ResetPassword",
  component: resetPassword,
  children: null,
};

const VersionSelectionRoutes = {
  id: "version",
  path: "/selection/portal/app/:App/licenses/versions",
  name: "Version Selection",
  component: versionSelection,
  children: null,
};
const LicenseSelectionRoutes = {
  id: "license",
  path: "/selection/licenses",
  name: "License Selection",
  component: licenseSelection,
  children: null,
};

const AppsPortalRoutes = {
  id: "portal",
  path: "/selection/portal/apps",
  name: "AppsPortal",
  component: appsPortal,
  children: null,
};

const ProfileRoutes = {
  id: "profiles",
  path: "/profiles",
  icon: <Unlock />,
  component: profile,
  children: null,
};

const UserRoutes = {
  id: "users",
  path: "/users",
  icon: <Users />,
  component: user,
  children: null,
};

const VersionRoutes = {
  id: "versions",
  path: "/versions",
  icon: <Folder />,
  component: version,
  children: null,
};

const RenewSessionRoutes = {
  id: "renew",
  path: "/renewsession",
  icon: null,
  component: renewsession,
  children: null,
};

const IntegrationRoutes = {
  id: "integrations",
  path: "/integrations",
  icon: <Link />,
  component: integrations,
  children: null,
};

export const login = [LoginRoutes];
export const portal = [AppsPortalRoutes];
export const versie = [VersionSelectionRoutes];
export const licentie = [LicenseSelectionRoutes];
export const verificatie = [ValidateRoutes];
export const requestreset = [ResetPasswordRoutes];
export const requestpassword = [RequestRoutes];
export const newpassword = [ResetRoutes];
export const validateLogin = [TwoFactorRoutes];
export const dashboard = [
  VersionRoutes,
  ProfileRoutes,
  UserRoutes,
  IntegrationRoutes,
];
export const renew = [RenewSessionRoutes];

// export default [VersionRoutes, ProfileRoutes, UserRoutes, IntegrationRoutes];
