import { createSelector } from "redux-orm";

import scheme from "../models/orm";

// auth versions
export const allLicVersions = createSelector(scheme, (orm) => {
  return orm.AuthVersions.all()
    .toModelArray()
    .map(({ licversionsid, versioncode, versiondescr }) => ({
      id: licversionsid,
      code: versioncode,
      description: versiondescr,
    }));
});
