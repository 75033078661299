import { createSelector } from "redux-orm";
import scheme from "../models/orm";
import { groupBy } from "lodash";

//  default profielsettings
export const defaultProfileSettings = createSelector(scheme.ProfileSettings);

// alle profielensettings groepeerd bij profileID
export const profileSettings = createSelector(scheme, (orm) => {
  const authGroups = orm.ProfileSettings.all()
    .toRefArray()
    .flatMap((item) => item.defAuthGroups);
  return groupBy(authGroups, (item) => item.ProfileAuths[0].profilesid);
  //.map(({$id, code, description,profileAuths})=>[$id, code, description,profileAuths])
});

// profiels defauthlevels --> waarde van de RDO, NOA, MDF opties
export const defaultSettings = createSelector(scheme, (orm) => {
  return orm.ProfileSettings.all()
    .toModelArray()
    .map((item) => item.defAuthLevels)[0];
});
