import styled, { createGlobalStyle } from "styled-components";
import { CssBaseline } from "@material-ui/core";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%; 
  }
  body {
    background: white;    
  }
`;

const Root = styled.div`
  display: flex;
  height: 100vh;
`;

function Landing({ children }) {
  return (
    <Root>
      <>
        <CssBaseline />
        <GlobalStyle />
        {children}
      </>
    </Root>
  );
}

export default Landing;
