import { Tooltip, Fab as MuiFab } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import styled from "styled-components";
import { primaryColor, secondaryColor } from "../../redux/constants";

const Fab = styled(MuiFab)`
  position: fixed;
  right: ${(props) => props.theme.spacing(8)}px;
  bottom: ${(props) => props.theme.spacing(8)}px;
  z-index: 1;
`;
export default function FabButton({ title, onClick }) {
  return (
    <Tooltip title={title} aria-label="addUser" onClick={onClick}>
      <Fab
        style={{
          background: `linear-gradient(to bottom, ${primaryColor} 21%, ${secondaryColor} 99%)`,
          color: "white",
        }}
      >
        <Add />
      </Fab>
    </Tooltip>
  );
}
