import { combineReducers } from "redux";
import { createReducer } from "redux-orm";
import scheme from "../models/orm";
import themeReducer from "./themeReducers";
import snackbarReducer from "./snackbarReducers";
import { LOGOUT } from "../constants";

const schemeReducer = createReducer(scheme);
const clearSchemeReducer = (state, action) => {
  if (action.type === LOGOUT) {
    return scheme.getEmptyState();
  } else {
    return schemeReducer(state, action);
  }
};

export default combineReducers({
  themeReducer,
  snackbarReducer,
  scheme: clearSchemeReducer,
});
