import { ERROR } from "../../redux/constants";
import PropTypes from "prop-types";
import translate from "i18next";
import styled, { useTheme } from "styled-components";
import { TableCell, useMediaQuery, Box } from "@material-ui/core";

//dag van vandaag
var dag = (new Date().getDate() < 10 ? "0" : "") + new Date().getDate();
var month =
  (new Date().getMonth() + 1 < 10 ? "0" : "") + (new Date().getMonth() + 1);
var year = new Date().getFullYear();
export let today = year + "-" + month + "-" + dag;

function randomBetween(a, b) {
  const max = Math.max(a, b);
  const min = Math.min(a, b);
  return Math.floor(Math.random() * (max - min) + min);
}

// Get an integer hashCode for the given String
function hashCode(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return hash;
}

// Get a random function for the given Integer seed
// https://stackoverflow.com/a/47593316/15469537
function mulberry32(seed) {
  return function () {
    let t = (seed += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
  };
}

export function randomColor2(color1, color2, seed = null) {
  const firstColor = color1 ?? "#A70C00";
  const secondColor = color2 ?? "#FFDF00";
  const first = firstColor.toUpperCase().substring(1, secondColor.length);
  const second = secondColor.toUpperCase().substring(1, firstColor.length);
  const scale = "0123456789ABCDEF";
  let color = "#";

  // Seeded random function, for consistent random colors after reloads etc.
  let randomGenerator;
  if (seed) {
    randomGenerator = mulberry32(hashCode(seed));
  }

  for (let i = 0; i < first.length && i < second.length; i++) {
    const random = randomBetween(
      scale.indexOf(first[i]),
      scale.indexOf(second[i]),
      randomGenerator
    );

    color += scale[random];
  }
  return color;
}

export const handleErrors = (error) => (dispatch) => {
  const status = error?.response?.status;

  if (error.response) {
    /*
     * Bepaal welke melding getoond moet worden indien response is aanwezig
     */

    // bij 401 error redirect naar inloggen
    if (status === 401) {
      // window.location.href = "/";
      window.location.href = "/renewsession";
    } else {
      dispatch({
        type: ERROR,
        payload: translate.t(
          `error.${error.response?.data?.error?.code}`,
          error.response?.data?.error?.message || error.response?.data
        ),
      });
    }
  } else {
    dispatch({ type: ERROR, payload: error.message });
  }
};

/*
 * Dit is een functie die een boolean terug geeft of je in mobiel of desktop modus zit.
 * Naam geeft aan dat dit een component is, hoewel dit een normale functie is.
 * Dit is gedaan om de useTheme te implementeren, want dit kun je ALLEEN bij een component gebruiken.
 */
export function IsMobile() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("sm"));
}

/*de naam zegt het al: er zit een bug in MUIDataTable. Door deze functie aan te roepen in een component
 *dat de datatable gebruikt ben je van de rode lettertjes in de console af.
 */
export function MUIDatatableIsEmptyBugFix() {
  const oldRender = TableCell.render;

  return (TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  });
}

export const TextFieldContainer = styled("div")(({ styles }) => ({
  "& .MuiOutlinedInput-input": {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
    },
  },
}));

export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <span>{children}</span>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export function formatLanguage(lang, isFromDB) {
  if (isFromDB) {
    switch (lang) {
      case "NLD":
      case "nl":
        return "nl";

      case "ENG":
      case "en":
        return "en";

      case "DEU":
      case "de":
        return "de";

      default:
        return "en";
    }
  } else {
    switch (lang) {
      case "nl":
        return "NLD";

      case "en":
        return "ENG";

      case "de":
        return "DEU";

      default:
        return "ENG";
    }
  }
}

// lijst van alle evo-it gebruikers
export const evoitUsers = [
  "jcnrobroeks@evo-it.nl",
  "remerenciana@evo-it.nl",
  "fmeert@evo-it.nl",
  "mtoussaint@evo-it.nl",
  "fvollebregt@evo-it.nl",
  "aintveen@evo-it.nl",
];
