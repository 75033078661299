import { SUCCESS } from "../../constants";
import translate from "i18next";
import { handleErrors } from "../../../utils";
import apiService from "../../../api/apiService";
import { fetchVersions } from "../versionActions";

// haal de sessie bij de geselecteerde app op
export const getSessions = (session) => async (dispatch) => {
  try {
    const response = await apiService.get(`myusersessions`, session);

    return response.data.value;
  } catch (error) {
    // toon foutmelding
    dispatch(handleErrors(error));
  }
};

// sluit de sessie bij de geselecteerde app op
export const endSession =
  (sessionId, option, history, defappsid) => async (dispatch) => {
    try {
      await apiService.delete(`kickusersessions`, {
        usersessionsid: sessionId,
      });
      // in applicatiebeheer update daarna de versie
      if (option === "versions") {
        dispatch(fetchVersions(defappsid));
        dispatch({
          type: SUCCESS,
          payload: translate.t("sessionComponent.successMessage"),
        });
      }
      // niet in applicatiebeheer toon success bericht
      else {
        return true;
      }
    } catch (error) {
      // toon foutmelding
      dispatch(handleErrors(error));
    }
  };
