import { SUCCESS } from "../../constants";
import Axios from "axios";
import { getURL, handleErrors } from "../../../utils";
import apiService from "../../../api/apiService";

// email opnieuw versturen
export const resendEmail = (id) => async (dispatch) => {
  try {
    await apiService.post(`requestemailverification`, { usersid: Number(id) });

    dispatch({
      type: SUCCESS,
      payload: `Email sent to user - ${id}`,
    });
  } catch (error) {
    // toon foutmelding
    dispatch(handleErrors(error));
  }
};

// verify email
export const verifyEmail = (key, history) => async (dispatch) => {
  try {
    await Axios.post(`${getURL()}verifyemail`, { key: key });
  } catch (error) {
    history.push("/");
    // toon foutmelding
    dispatch(handleErrors(error));
  }
};
