import { createSelector } from "redux-orm";
import scheme from "../models/orm";

// standaard versies
export const defaultVersions = createSelector(scheme.Versions);

// gefilterde versie
export const getVersionsByDefappsId = createSelector(
  scheme,
  (state, currentDefappsId) => currentDefappsId,
  (orm, currentDefappsId) => {
    return orm.Versions?.all()
      ?.toRefArray()
      ?.filter((v) => v.defappsid === currentDefappsId)
      ?.map((v) => ({
       
        licappsid: v.licappsid,
        code: v.code,
        description: v.description,
        linkedUsersAmount: v.userlicapplinks.length,
        linkedUsers: v.userlicapplinks, licversionsid:v.licversionsid
      }))
      ?.sort((a, b) => {
        if (a.description.toUpperCase() < b.description.toUpperCase()) {
          return -1;
        }
        if (a.description.toUpperCase() > b.description.toUpperCase()) {
          return 1;
        }
        return 0;
      });
  }
);

export const amountLicenseUsers = createSelector(scheme.AmountLicenseUsers);
