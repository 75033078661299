import { createSelector } from "redux-orm";
import scheme from "../models/orm";

// standaard profielen
export const profiles = createSelector(scheme.Profiles);

// sorteerde- en gefilterd profielen
export const getProfilesByDefappsId = createSelector(
  scheme,
  (orm, currentDefappsId) => currentDefappsId,
  (orm, currentDefappsId) => {
    return orm.Profiles?.all()
      .toRefArray()
      ?.filter((p) => p.defappsid === currentDefappsId)
      ?.sort((a, b) => {
        if (a.description.toUpperCase() < b.description.toUpperCase()) {
          return -1;
        }
        if (a.description.toUpperCase() > b.description.toUpperCase()) {
          return 1;
        }
        return 0;
      });
  }
);
