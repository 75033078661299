import { ERROR, UPSERT_AUTHLICENSES } from "../../constants";
import translate from "i18next";
import { handleErrors } from "../../../utils";
import { getLicenseVersions } from "./versions";
import { getApps } from "./apps";
import apiService from "../../../api/apiService";

// Get licenses
export const getLicenses = (history) => async (dispatch) => {
  try {
    const response = await apiService.get(`auth_licences`);

    dispatch({
      type: UPSERT_AUTHLICENSES,
      payload: response.data.value,
    });
  } catch (error) {
    // toon foutmelding
    dispatch(handleErrors(error));
  }
};

export const getLicensesWithRedirect = (history, cntxt) => async (dispatch) => {
  try {
    const response = await apiService.get(`auth_licences`);

    dispatch({
      type: UPSERT_AUTHLICENSES,
      payload: response.data.value,
    });

    //als de gebruiker alleen aan 1 licentie gekoppeld is, redirect naar de Appsportal pagina.
    if (Object.keys(response.data.value).length === 1) {
      sessionStorage.setItem(
        "app_user_license",
        response.data.value[0].licensesid
      );

      dispatch(
        handleLicenseLogin(response.data.value[0].licensesid, history, cntxt)
      );
    }
    //als de gebruiker aan meerdere licentie is gekoopeld, redirect naar de Keuzelicentie pagina.
    else {
      if (window.location.pathname !== "/selection/licenses") {
        // als de default licentie is al gevuld vanuit de url deze stap ook overslaan en direct op de licentie inloggen anders redirect naar d keuzelicentie pagina
        if (sessionStorage.getItem("app_user_license")) {
          dispatch(
            handleLicenseLogin(
              Number(sessionStorage.getItem("app_user_license")),
              history,
              cntxt
            )
          );
        } else {
          history.push("/selection/licenses");
        }
      }
    }
  } catch (error) {
    // Indien een gebruiker is gemaakt maar niet aan een versie is gekoppeld
    if (error.response && error.response.data.error.code === "-310025") {
      dispatch({
        type: ERROR,
        payload: translate.t("error.noLicense"),
      });
    } else {
      // toon foutmelding
      dispatch(handleErrors(error));
    }
  }
};

// License login
export const handleLicenseLogin = (id, history, cntxt) => async (dispatch) => {
  try {
    const response = await apiService.post(`loginlicense`, { licensesid: id });
    //verwijderd de oude token en sla de nieuwe token op
    sessionStorage.setItem("default_oauth", response.data.value);
    // check of de default app aanwezig is en zo niet redirect naar de apps portal
    if (sessionStorage.getItem("default_app_code") !== null) {
      // vraag alle apps
      const apps = await dispatch(getApps(history, 1));

      if (apps) {
        const defaultApp = apps?.find(
          (app) => app.code === sessionStorage.getItem("default_app_code")
        );
        // vraag alle versies van de geselecteerde app
        dispatch(
          getLicenseVersions(
            defaultApp.defappsid,
            defaultApp.code,
            history,
            cntxt
          )
        );
      }
    } else {
      // redirect naar apps portal indien history is niet null
      if (history !== undefined) {
        history.push("/selection/portal/apps");
      }
    }
  } catch (error) {
    // toon foutmelding
    dispatch(handleErrors(error));
  }
};
