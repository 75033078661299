import { createSelector } from "redux-orm";

import scheme from "../models/orm";

// gefilterde sessie
export const sessions = createSelector(scheme, (orm) => {
  return orm.Session.all()
    .toModelArray()
    .map(({ sessionid, ip, logindatetime }) => ({
      sessionid,
      ip,
      date: logindatetime.substring(0, 10),
      time: logindatetime.substring(11, 19),
    }));
});
