import { Model, attr } from "redux-orm";
import { UPSERT_SESSION, DELETE_SESSION, RESET_SESSION } from "../constants";

class Session extends Model {
  static reducer(action, session) {
    switch (action.type) {
      case UPSERT_SESSION:
        session.upsert(action.payload);
        break;
      case DELETE_SESSION:
        session.withId(action.payload).delete();
        break;
      case RESET_SESSION:
        session.delete();
        break;
      default:
        break;
    }
  }
}
Session.modelName = "Session";

Session.options = {
  idAttribute: "sessionid", // default idd
};
// attributen
Session.fields = {
  $id: attr(),
  sessionid: attr(),
  licappsid: attr(),
  defappsid: attr(),
  logindatetime: attr(),
  ip: attr(),
};

export default Session;
