import React, { useState } from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { NavLink as RouterNavLink, withRouter } from "react-router-dom";
import { darken } from "polished";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../../vendor/perfect-scrollbar.css";
import {
  Chip,
  Collapse,
  Drawer as MuiDrawer,
  IconButton as MuiIconButton,
  Grid,
  List as MuiList,
  ListItem,
  ListItemText,
  Link as LinkBrand,
  Typography,
} from "@material-ui/core";
import { ExpandLess, ExpandMore, ExitToApp } from "@material-ui/icons";
import { dashboard as routes } from "../../routes/routes";
import { useTranslation } from "react-i18next";
import LanguagesMenu from "./Languages";
import useLogout from "../../auth/useLogout";

//logo navigatie
const LogoApb = styled.img`
  border-radius: 50%;
`;

const NavLink = React.forwardRef(function Nav(props, ref) {
  return <RouterNavLink innerRef={ref} {...props} />;
});

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;
const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;

  padding-left: ${(props) => props.theme.spacing(4)}px;
  padding-right: ${(props) => props.theme.spacing(3)}px;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 155px;
  }
`;

const BrandIcon = styled(LogoApb)`
  margin-right: ${(props) => props.theme.spacing(2)}px;
  color: ${(props) => props.theme.sidebar.header.brand.color};
`;

// .svg--> opacity: 0.5 sidebar icoon verwijderd;
const Category = styled(ListItem)`
  //padding-top: ${(props) => props.theme.spacing(3)}px;
  //padding-bottom: ${(props) => props.theme.spacing(3)}px;
  //padding-left: ${(props) => props.theme.spacing(6)}px;
  //padding-right: ${(props) => props.theme.spacing(5)}px;
  //font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 23px;
    height: auto;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      darken(0.05, props.theme.sidebar.background)};
    border-style: solid;
    border-color: ${"transparent"};
    border-left-color: ${(props) => props.theme.sidebar.color};
    //border-right-color:  ${"#FFDF00"};
    //border-left-color:  ${"#FFDF00"};
    span {
      //color: ${"#FFDF00"};
      color: ${(props) => props.theme.sidebar.color};
    }
    svg {
      //color: ${"#FFDF00"};
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  //margin-right : auto;
  //margin-left: ${(props) => props.theme.spacing(3)}px;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    font-weight: ${(props) => props.theme.sidebar.category.fontWeight};
    //padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(15)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      darken(0.06, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 12px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(6)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

const SidebarFooter = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}px
    ${(props) => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
    color: white;
    margin-right: 5px;
    margin-left: 5px;
  }
  color: white;
  font-size: 18px;
`;

function SidebarCategory({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  ...rest
}) {
  return (
    <Category {...rest}>
      <Grid
        container
        direction="column"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item xs>
          {icon}
        </Grid>
        <Grid item xs>
          <CategoryText>{name}</CategoryText>
        </Grid>
      </Grid>

      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess />
        )
      ) : null}
      {badge ? <CategoryBadge label={badge} /> : ""}
    </Category>
  );
}

function SidebarLink({ name, to, badge }) {
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
    >
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
    </Link>
  );
}

function Sidebar({ classes, staticContext, location, ...rest }) {
  const { t } = useTranslation();
  const initOpenRoutes = () => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname;

    let _routes = {};

    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/";

      _routes = Object.assign({}, _routes, {
        [index]: isActive || isOpen || isHome,
      });
    });

    return _routes;
  };

  const logout = useLogout();
  const handleLogout = () => {
    logout();
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  return (
    <Drawer variant="permanent" {...rest}>
      <Brand>
        <LinkBrand href="/versions">
          <BrandIcon
            style={{ width: "90px" }}
            src="/static/applicatiebeheer.svg"
            alt="Logo"
          />
        </LinkBrand>
      </Brand>

      <Scrollbar>
        <List disablePadding>
          <Items>
            {routes.map((category, index) => (
              <React.Fragment key={category.id}>
                {category.header ? (
                  <SidebarSection>{category.header}</SidebarSection>
                ) : null}

                {category.children ? (
                  <React.Fragment key={category.id}>
                    <SidebarCategory
                      isOpen={!openRoutes[index]}
                      isCollapsable={true}
                      name={category.id}
                      icon={category.icon}
                      button={true}
                      onClick={() => toggle(index)}
                    />

                    <Collapse
                      in={openRoutes[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      {category.children.map((route) => (
                        <SidebarLink
                          key={route.name}
                          name={route.name}
                          to={route.path}
                          icon={route.icon}
                          badge={route.badge}
                        />
                      ))}
                    </Collapse>
                  </React.Fragment>
                ) : (
                  <SidebarCategory
                    isCollapsable={false}
                    name={t(`sidebarComponent.${category.id}`)}
                    to={category.path}
                    activeClassName="active"
                    component={NavLink}
                    icon={category.icon}
                    exact
                    badge={category.badge}
                  />
                )}
              </React.Fragment>
            ))}
          </Items>
        </List>
      </Scrollbar>
      <SidebarFooter>
        <Grid container alignItems="center" spacing={4} direction="column">
          <Grid item xs={12}>
            <Grid container direction="column" alignItems="center" spacing={0}>
              <Grid item xs>
                <Grid container direction="column" alignItems="center">
                  <Grid item xs>
                    <LanguagesMenu />
                  </Grid>

                  <Grid item xs>
                    <Typography style={{ color: "white" }}>
                      {t("sidebarComponent.language")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* 
          
          // tijdelijk uitschakelen, want we doen niks met help hier
          <Grid item xs={12}>
            <Grid container direction="column" alignItems="center" spacing={0}>
              <Grid item xs>
                <IconButton size="small">
                  <Grid container direction="column" alignItems="center">
                    <Grid item xs>
                      <HelpOutline style={{ height: "30px", width: "30px" }} />
                    </Grid>

                    <Grid item xs>
                      <Typography style={{ color: "white" }}>
                        {t("sidebarComponent.help")}
                      </Typography>
                    </Grid>
                  </Grid>
                </IconButton>
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item xs={12}>
            <Grid container direction="column" alignItems="center" spacing={0}>
              <Grid item xs>
                <IconButton onClick={() => handleLogout()} size="small">
                  <Grid container direction="column" alignItems="center">
                    <Grid item xs>
                      <ExitToApp style={{ height: "30px", width: "30px" }} />
                    </Grid>

                    <Grid item xs>
                      <Typography style={{ color: "white" }}>
                        {t("sidebarComponent.exit")}
                      </Typography>
                    </Grid>
                  </Grid>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12}>
            <IconButton
              onClick={() => {
                handleExpand();
              }}
              size="small"
            >
              {expanded ? <ChevronLeft /> : <ChevronRight color="primary" />}
            </IconButton>
          </Grid> */}
        </Grid>
      </SidebarFooter>
    </Drawer>
  );
}

export default withRouter(Sidebar);
