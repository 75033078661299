import React from "react";
import styled from "styled-components";
import {
  Grid,
  Hidden,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { capitalize } from "lodash";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
    backgroundcolor: "transparent";
  }
`;

function Header({ onDrawerToggle }) {
  const { t } = useTranslation();

  const handleHeaderName = () => {
    return (
      window.location.pathname.substring(
        1,
        window.location.pathname.length - 1
      ) ?? t("portalComponent.apmName")
    );
  };

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Grid container alignItems="center" spacing={1}>
          {/* in mobiele modus verberg de sidebar en toon de menu knop */}
          <Hidden mdUp>
            <Grid item xs={1} align="center">
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs={11}>
              <Typography variant="h6" color="primary">
                {capitalize(handleHeaderName())}
              </Typography>
            </Grid>
          </Hidden>
        </Grid>
      </AppBar>
    </React.Fragment>
  );
}

export default Header;
