import { UPSERT_MENU } from "../constants";
import { handleErrors } from "../../utils";
import apiService from "../../api/apiService";

// haal alle menu apps
export const fetchDefapps = () => async (dispatch) => {
  try {
    const response = await apiService.get(`appmanagement/defapps`);

    dispatch({
      type: UPSERT_MENU,
      payload: response.data.value,
    });
  } catch (error) {
    // toon foutmelding
    dispatch(handleErrors(error));
  }
};
