import { useState, Fragment } from "react";
import styled from "styled-components";
import "../../vendor/perfect-scrollbar.css";
import {
  IconButton as MuiIconButton,
  Grid,
  Typography,
  Avatar,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getLanguages } from "../../utils";
import { useTranslation } from "react-i18next";
const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
    color: white;
    margin-right: 5px;
    margin-left: 5px;
  }
  color: white;
  font-size: 18px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const Flag = styled.img`
  border-radius: 50%;
  width: 30px;
  height: 30px;
`;

function LanguagesMenu() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(
    localStorage.getItem("i18nextLng")
  );
  const languages = getLanguages();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);

    i18n.changeLanguage(index);
    setAnchorMenu(null);
  };
  const closeMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <Fragment>
      <IconButton
        name={"languageButton"}
        aria-owns={anchorMenu ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
        style={{ boxShadow: "none" }}
        size="small"
      >
        <Flag
          name={"flag"}
          src={`/flags/${selectedIndex}.png`}
          alt={selectedIndex}
        />
      </IconButton>

      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {languages.map((lang) => {
          return (
            <MenuItem
              style={{ minWidth: "180px" }}
              name={lang}
              key={lang}
              selected={lang === selectedIndex}
              onClick={(event) => handleMenuItemClick(event, lang)}
            >
              <Grid container direction="row">
                <Grid item xs={3}>
                  {lang === "nl" && (
                    <Avatar
                      className={classes.small}
                      src="/flags/nl.png"
                      alt="Dutch"
                    />
                  )}
                  {lang === "en" && (
                    <Avatar
                      className={classes.small}
                      src="/flags/en.png"
                      alt="English"
                    />
                  )}
                  {lang === "de" && (
                    <Avatar
                      className={classes.small}
                      src="/flags/de.png"
                      alt="German"
                    />
                  )}
                </Grid>
                <Grid item xs={9}>
                  <Typography>
                    {t(`headerComponent.languages.${lang}`)}
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
          );
        })}
      </Menu>
    </Fragment>
  );
}

export default LanguagesMenu;
