import { Model, attr } from "redux-orm";
import {
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  UPSERT_USER,
} from "../constants";

class Users extends Model {
  static reducer(action, users) {
    switch (action.type) {
      case CREATE_USER:
        users.create(action.payload);
        break;
      case UPSERT_USER:
        action?.payload?.forEach((user) => {
          users.upsert(user);
        });
        break;
      case UPDATE_USER:
        users.withId(action.payload.usersid).update(action.payload);
        break;
      case DELETE_USER:
        users.withId(action.payload).delete();
        break;
      default:
        break;
    }
  }

  get fullName() {
    return [this.firstname, this.infixname, this.lastname]
      .filter((part) => part)
      .join(" ");
  }
}
Users.modelName = "Users";

Users.options = {
  idAttribute: "usersid", // default idd
};
// attributen
Users.fields = {
  usersid: attr(),
  firstname: attr(),
  infixname: attr(),
  lastname: attr(),
  loginemail: attr(),
  expdate: attr(),
  mobile: attr(),
  evoit: attr(),
  emailValidated: attr(),
  languagecode: attr(),
  defapps: attr(),
};

export default Users;
