import Routes from "./routes/index";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import React from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { ThemeProvider } from "styled-components";
import "./App.css";
import maTheme from "./theme";
import UseInterceptors from "./api/useInterceptors";
import Snackbars from "./components/Snackbars";
import { useEffect } from "react";
import { getApbRefreshToken } from "./utils";
import jwtDecode from "jwt-decode";
import { useState } from "react";
import AuthLayout from "./layouts/Auth";
import useRefreshToken from "./auth/useRefreshToken";
import { Box, CircularProgress } from "@material-ui/core";
import RenewSession from "./auth/RenewSession";

function App({ theme }) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const refresh = useRefreshToken();

  useEffect(() => {
    // refresh token aanwezig valideer de jwt ander redirect naar inlogscherm
    if (getApbRefreshToken()) {
      try {
        // controleer of url bevat een gelidige jwt die bestaat uit 3 gedeelte bijv. 'eyJ0eXAiOiJKV1Qi........'
        if (jwtDecode(getApbRefreshToken())) {
          // eslint-disable-next-line more/no-then
          refresh()
            .then((res) => {
              setIsLoading(false);
            })
            .catch((err) => {
              setError(true);
            });
        }
      } catch (error) {
        window.location.href = `/`;
      }
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <React.Fragment>
      <Helmet titleTemplate={"%s |  EVO-IT"} defaultTitle="EVO-IT" />
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
            <ThemeProvider theme={maTheme[theme.currentTheme]}>
              <UseInterceptors>
                <Snackbars />
                {isLoading ? (
                  <AuthLayout>
                    {!error ? (
                      <Box>
                        <CircularProgress /> <p>Loading...</p>
                      </Box>
                    ) : (
                      <RenewSession />
                    )}
                  </AuthLayout>
                ) : (
                  <Routes />
                )}
              </UseInterceptors>
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default connect((store) => ({ theme: store.themeReducer }))(App);
