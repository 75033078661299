import { ERROR } from "../../constants";
import Axios from "axios";
import translate from "i18next";
import base32 from "hi-base32";
import {
  setUserLang,
  getURL,
  getUserLang,
  setUserTemp1,
  setUserTemp2,
  removeUserTemp,
  setTypeMFA,
} from "../../../utils";
import { getLicensesWithRedirect } from "./licenses";

// Login
export const login =
  (gegevens, history, appcode, license, version, auth, setAuth) =>
  async (dispatch) => {
    try {
      const response = await Axios.post(`${getURL()}login`, gegevens, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      // login gelukt verwijderd user login gegevens en type mfa
      removeUserTemp();
      // sla de gebruiker id  & default taal op
      sessionStorage.setItem("app_user_id", response.data.User.usersid);
      setUserLang(response.data.User.languagecode);
      // default appcode opslaan indien niet leeg
      if (appcode !== undefined) {
        sessionStorage.setItem("default_app_code", appcode);
      }
      // default licentie opslaan indien niet leeg
      if (license !== undefined) {
        sessionStorage.setItem("app_user_license", license);
      }
      // default versie opslaan indien niet leeg
      if (version !== undefined) {
        sessionStorage.setItem("app_user_version", version);
      }
      // sla de token op
      sessionStorage.setItem("default_oauth", response.data.access_token);
      translate.changeLanguage(getUserLang());
      // vraag de licenties op
      dispatch(getLicensesWithRedirect(history, { auth, setAuth }));
    } catch (error) {
      if (error.response !== undefined) {
        // 2Factor required**
        if (
          error.response.data.error.code === "-310082" ||
          error.response.data.error.code === "-310081"
        ) {
          //appcode opslaan
          if (appcode !== undefined) {
            sessionStorage.setItem("default_app_code", appcode);
          }
          // ga naar 2fa
        
            validateLogin(history, gegevens, error.response.data.error.code)
          
        } else {
          dispatch({
            type: ERROR,
            payload: translate.t([
              `error.${error.response.data.error.code}`,
              "error.undefined",
            ]),
          });
        }
      } else {
        dispatch({ type: ERROR, payload: error.message });
      }
    }
  };

// twofactorauth
export const validateLogin = (history, data, code) =>  {
  // tijdelijk opslaan van de gebruikers inloggevens in de sessionStorage
  // inloggegevens encrypten base32
  setUserTemp1(base32.encode(data?.Email));
  setUserTemp2(base32.encode(data?.Password));
  if (code === "-310081") {
    //nieuw key en code required
    setTypeMFA(-1);
  } else if (code === "-310082") {
    // only code required
    setTypeMFA(1);
  } else {
    // do nothing
  }
  // redirect naar 2FA pagina
  history.push("/validate");
};
export const verifycode = () => (dispatch) => {
  dispatch({
    type: ERROR,
    payload: translate.t([`error.${-310080}`, "error.undefined"]),
  });
};
