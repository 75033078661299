import { createSelector } from "redux-orm";

import scheme from "../models/orm";

// datum van vandaag
var day = (new Date().getDate() < 10 ? "0" : "") + new Date().getDate();
var month =
  (new Date().getMonth() + 1 < 10 ? "0" : "") + (new Date().getMonth() + 1);
var year = new Date().getFullYear();
let today = year + "-" + month + "-" + day;

function formatDate(date) {
  if (date === null || date === undefined) {
    return "";
  } else {
    return date.substring(0, 10);
  }
}

// status wordt obv einddatum en emailvalidated bepaald
function formatStatus(date, emailvalidated) {
  if (
    (date >= today && emailvalidated === true) ||
    (date === "" && emailvalidated === true)
  ) {
    return "active";
  } else if (date < today && emailvalidated === true) {
    return "inactive";
  } else if (
    (date >= today && emailvalidated === false) ||
    (date === "" && emailvalidated === false)
  ) {
    return "pending";
  } else {
    return "inactive";
  }
}

// alle gebruikers gegevens
export const users = createSelector(scheme.Users);

// alle gebruikers linked defapps
export const userDefapps = createSelector(scheme.Users, (users) => {
  return users.map((d) => ({
    id: d.usersid,
    defapps: d.defapps.filter((p) => p.code !== "APM"),
    amount: d.defapps.filter((p) => p.code !== "APM").length,
  }));
});

// alle gebruikers om userstabel te vullen
export const allUsers = createSelector(scheme, (orm) => {
  return orm.Users.all()
    .toRefArray()
    .sort((a, b) => {
      if (a.firstname.toUpperCase() < b.firstname.toUpperCase()) {
        return -1;
      }
      if (a.firstname.toUpperCase() > b.firstname.toUpperCase()) {
        return 1;
      }
      return 0;
    })
    .map((usr) => ({
      ...usr,
      fullName: [usr.firstname, usr.infixname, usr.lastname]
        .filter((part) => part)
        .join(" "),
      isAdmin: usr.defapps.some((i) => i.code === "APM"),
      lastlogin: formatDate(usr.lastlogin),
      expdate: formatDate(usr.expdate),
      status: formatStatus(formatDate(usr.expdate), usr.emailverified),
    }));
});
// alle gebruikers linked defapps
export const availableUsers = createSelector(scheme, (orm) => {
  return orm.Users.all()
    .toModelArray()
    .sort((a, b) => {
      if (a.firstname.toUpperCase() < b.firstname.toUpperCase()) {
        return -1;
      }
      if (a.firstname.toUpperCase() > b.firstname.toUpperCase()) {
        return 1;
      }
      return 0;
    })
    .map(({ usersid, loginemail, fullName }) => [
      usersid,
      loginemail,
      fullName,
    ]);
});
