import React from "react";

import Loader from "./Loader";

// const sleep = (m) => new Promise((r) => setTimeout(r, m));
// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = async function (componentImport) {
  // check if the window has already been refreshed
  const hasRefreshed = JSON.parse(
    window.sessionStorage.getItem("retry-lazy-refreshed") || "false"
  );
  try {
    // try to import the component
    const component = await componentImport();
    window.sessionStorage.setItem("retry-lazy-refreshed", "false"); // success so reset the refresh
    return component;
  } catch (error) {
    if (!hasRefreshed) {
      // not been refreshed yet
      window.sessionStorage.setItem("retry-lazy-refreshed", "true"); // we are now going to refresh
      window.location.reload(); // refresh the page
    } else {
      throw error; // Default error behaviour as already tried refresh
    }
  }
};

export default function asyncComponent(importComponent) {
  class AsyncComponent extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        component: null,
      };
    }

    async componentDidMount() {
      // await sleep(150);

      const { default: component } = await lazyRetry(importComponent);

      this.setState({
        component: component,
      });
    }

    render() {
      const C = this.state.component;

      return C ? <C {...this.props} /> : <Loader />;
    }
  }

  return AsyncComponent;
}
