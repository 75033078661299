import { Model, attr } from "redux-orm";
import {
  CREATE_INTEGRATIONS,
  UPDATE_INTEGRATIONS,
  DELETE_INTEGRATIONS,
  UPSERT_INTEGRATIONS,
} from "../constants";

class Integrations extends Model {
  static reducer(action, integrations) {
    switch (action.type) {
      case CREATE_INTEGRATIONS:
        integrations.create(action.payload);
        break;
      case UPSERT_INTEGRATIONS:
        action?.payload?.forEach((api) => {
          integrations.upsert(api);
        });
        break;
      case UPDATE_INTEGRATIONS:
        integrations.withId(action.payload.apikeysid).update(action.payload);
        break;
      case DELETE_INTEGRATIONS:
        integrations.withId(action.payload).delete();
        break;
      default:
        break;
    }
  }
}
Integrations.modelName = "Integrations";

Integrations.options = {
  idAttribute: "apikeysid", // default idd
};
// attributen
Integrations.fields = {
  apikeysid: attr(),
  licversionsid: attr(),
  aud: attr(),
  description: attr(),
};

export default Integrations;
