import { Model, attr } from "redux-orm";
import { UPSERT_AUTHLICENSES } from "../constants";

class AuthLicenses extends Model {
  static reducer(action, AuthLicenses) {
    switch (action.type) {
      case UPSERT_AUTHLICENSES:
        action.payload?.forEach((license) => {
          AuthLicenses.upsert(license);
        });

        break;
      default:
        break;
    }
  }
}
AuthLicenses.modelName = "AuthLicenses";

AuthLicenses.options = {
  idAttribute: "licensesid", // default idd
};
// attributen
AuthLicenses.fields = {
  $id: attr(),
  licenseesid: attr(),
  licenseedescr: attr(),
  licensesid: attr(),
  code: attr(),
  description: attr(),
  expdate: attr(),
};

export default AuthLicenses;
