import { ERROR, UPSERT_AUTHAPPS } from "../../constants";
import translate from "i18next";
import { handleErrors } from "../../../utils";
import { validateLogin } from "./login";
import { endSession, getSessions } from "./session";
import { getClientUrl } from "./helper";
import apiService from "../../../api/apiService";

// get auth apps
export const getApps = (history, type) => async (dispatch) => {
  try {
    const response = await apiService.get(`auth_apps`);

    dispatch({
      type: UPSERT_AUTHAPPS,
      payload: response.data.value,
    });

    if (Object.keys(response.data.value).length === 0) {
      history.push("/");
      dispatch({
        type: ERROR,
        payload: translate.t([`error.${"-310030"}`, "error.undefined"]),
      });
    }
    // retouneert de apps
    if (type !== undefined) {
      return response.data.value;
    }
  } catch (error) {
    // toon foutmelding
    dispatch(handleErrors(error));
  }
};

// App login
export const handleAppLogin = (data, context) => async (dispatch) => {
  let tokens;
  let res;
  // als de geselecteerde app is Applicatiebheer dan data is alleen defappsid
  // anders data is defappsid & licversionsid
  try {
    const response = await apiService.post(`loginapp`, data);
    // alleen voor applicatiebeheer tokens opslaan in context
    if (data.app === "APM") {
      context.setAuth({
        ...context.auth,
        access_token: response.data.access_token,
        refresh_token: response.data.refresh_token,
      });
    }
    //opslaan van de nieuwe Token in de sessionStorage
    sessionStorage.setItem(
      "app_oauth",
      JSON.stringify({
        // access_token: response.data.access_token,
        refresh_token: response.data.refresh_token,
        // expires_in: response.data.expires_in,
      })
    );
    tokens = {
      access_token: response.data.access_token,
      refresh_token: response.data.refresh_token,
    };
    // versie licentie opslaan
    if (data.licversionsid) {
      sessionStorage.setItem("app_user_version", data.licversionsid);
    }
    // redirect naar de geselecteerde app -> RITPLAN, REPOND, LMS, PLANNINGVIEWER, APPLICATIEBEHEER, LICENTIEBHEER
    switch (data.app.toUpperCase()) {
      case "APM":
        //main pagina applicatiebeheer

        data.history.push("/versions");
        break;
      case "REP":
        data.history.push("/");
        break;
      case "BAC":
        res = await getClientUrl(data.app.toUpperCase(), tokens);
        if (res) {
          sessionStorage.removeItem("app_oauth");
          window.location.href = res;
        }
        break;
      case "ELM":
        res = await getClientUrl(data.app.toUpperCase(), tokens);
        if (res) {
          sessionStorage.removeItem("app_oauth");
          window.location.href = res;
        }

        break;
      case "RPT":
        data.history.push("/");
        break;
      case "RIT":
        data.history.push(
          `/login/${response.data.access_token}/${response.data.refresh_token}`
        );
        break;
      default:
        data.history.push("/");
        break;
    }
  } catch (error) {
    // 2Factor required**
    if (
      error?.response?.data?.error?.code === "-310082" ||
      error?.response?.data?.error?.code === "-310081"
    ) {
      // ga naar 2fa
      validateLogin(data.history, [], error.response.data.error.code);
    } else if (
      String(error?.response?.data?.error?.code) === "-1011201" ||
      String(error?.response?.data?.error?.code) === "-1011202" ||
      String(error?.response?.data?.error?.code) === "-1011204" ||
      String(error?.response?.data?.error?.code) === "-1011203"
    ) {
      dispatch(handleErrors(error));
    } else {
      /*
       * als de gebruiker is al ingelogd actieve sessie kicken en opnieuw inloggen.
       * als app is applicatiebeheer of licentiebeheer gebruik alleen defappsid anders defappsid & versie
       */
      let sessie;
      let ended;
      if (data.app === "APM" || data.app === "ELM") {
        sessie = await dispatch(
          /*
           * vraag de actieve sessi eop
           */

          getSessions({
            defappsid: data.defappsid,
          })
        );

        if (sessie) {
          ended = await dispatch(
            /*
             * de actieve sessie sluiten
             */
            endSession(sessie[0]?.sessionid, "app", data.history)
          );
        }

        /*
         * sessie afgesloten, opnieuw inloggen anders redirect naar login scherm
         */
        ended
          ? dispatch(handleAppLogin(data, context))
          : data.history.push("/");
      } else {
        sessie = await dispatch(
          /*
           * vraag de actieve sessi eop
           */
          getSessions({
            defappsid: data.defappsid,
            licversionsid: data.licversionsid,
          })
        );

        if (sessie) {
          ended = await dispatch(
            /*
             * de actieve sessie sluiten
             */
            endSession(sessie[0]?.sessionid, "app", data.history)
          );
        }
        /*
         * sessie afgesloten, opnieuw inloggen anders redirect naar login scherm
         */
        ended
          ? dispatch(handleAppLogin(data, context))
          : data.history.push("/");
      }
    }
  }
};
