import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Page404 from "../auth/Page404";
import LandingLayout from "../layouts/Landing";
import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import { getToken } from "../utils";
import {
  verificatie as ValidateRoutes,
  dashboard as dashboardRoutes,
  portal as appsRoutes,
  versie as keuzeVersieRoutes,
  licentie as keuzeLicentieRoutes,
  requestreset as RequestResetRoutes,
  newpassword as ResetRoutes,
  requestpassword as RequestRoutes,
  login as loginRoutes,
  validateLogin as TwoFactorRoutes,
  renew as renewsessionRoutes,
} from "./routes";
import { useAuth } from "../auth/AuthProvider";

// PublicRoutes token niet nodig om bij de pagina te komen
const PublicRoutes = (Layout, routes) =>
  routes.map(({ path, component: Component, id }) => (
    // Route item without children
    <Route
      key={id}
      path={path}
      exact
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  ));

// PrivateRoutes token nodig om bij de pagina te komen
const privateAuthRoutes = (Layout, routes) =>
  routes.map(({ path, component: Component, id }) => (
    <Route
      key={id}
      path={path}
      render={(props) =>
        getToken() ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  ));

const privateAPMRoutes = (Layout, routes) => {
  return routes.map(({ path, component: Component, id }) => {
    const { auth } = useAuth();
    return (
      <Route
        key={id}
        path={path}
        render={(props) =>
          auth.access_token ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          )
        }
      />
    );
  });
};

const Routes = () => (
  <Router>
    <Switch>
      {privateAPMRoutes(DashboardLayout, dashboardRoutes)}
      {privateAuthRoutes(LandingLayout, appsRoutes)}
      {privateAuthRoutes(AuthLayout, keuzeVersieRoutes)}
      {privateAuthRoutes(AuthLayout, keuzeLicentieRoutes)}
      {PublicRoutes(AuthLayout, loginRoutes)}
      {PublicRoutes(AuthLayout, ValidateRoutes)}
      {PublicRoutes(AuthLayout, RequestResetRoutes)}
      {PublicRoutes(AuthLayout, ResetRoutes)}
      {PublicRoutes(AuthLayout, RequestRoutes)}
      {PublicRoutes(AuthLayout, TwoFactorRoutes)}
      {PublicRoutes(AuthLayout, renewsessionRoutes)}

      <Route
        render={() => (
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
    </Switch>
  </Router>
);

export default Routes;
