import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getVersion } from "../../utils";
import {
  Grid,
  List,
  ListItemText,
  ListItem as MuiListItem,
} from "@material-ui/core";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  background: ${(props) => props.theme.palette.common.white};
  position: relative;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  &,
  &:hover,
  &:active {
    color: #000;
  }
`;

function Footer() {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Grid container item xs={12} md={12} justifyContent="center">
          <List>
            <ListItem>
              <ListItemText
                primary={`© ${new Date().getFullYear()} - ${t(
                  "portalComponent.apmName"
                )}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="|" />
            </ListItem>
            <ListItem>
              <ListItemText primary={getVersion()} />
            </ListItem>
            <ListItem>
              <ListItemText primary={"|"} />
            </ListItem>
            <ListItem>
              <div>
                <a href="https://www.teamviewer.com/link/?url=505374&id=1621259485423">
                  <span>Remote Support</span>
                </a>
              </div>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
