import { SUCCESS, UPSERT_PROFILESETTINGS } from "../constants";
import { handleErrors } from "../../utils";
import translate from "i18next";
import apiService from "../../api/apiService";

// haal van de  geselecteerde profiel de settings op
export const fetchProfileSettings = (profilesid) => async (dispatch) => {
  try {
    const response = await apiService.get(`appmanagement/profileauth`, {
      profilesid: profilesid,
    });

    dispatch({
      type: UPSERT_PROFILESETTINGS,
      payload: { ...response.data, profilesid: profilesid },
    });
  } catch (error) {
    // toon foutmelding
    dispatch(handleErrors(error, fetchProfileSettings(profilesid)));
  }
};

// update de gweijzigde instelling
export const updateProfileSettings =
  (settings, profilesid) => async (dispatch) => {
    try {
      const response = await apiService.post(
        `appmanagement/profileauth`,

        [settings]
      );
      dispatch(fetchProfileSettings(profilesid));
      dispatch({
        type: SUCCESS,
        payload:
          translate.t("profileSettingsComponent.update") + `${response.data}`,
      });
    } catch (error) {
      // toon foutmelding
      dispatch(handleErrors(error));
    }
  };
