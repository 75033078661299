import { green, grey } from "@material-ui/core/colors";
import { primaryColor, secondaryColor } from "../redux/constants";

const evoitVariant = {
  name: "EVOIT",
  palette: {
    primary: {
      main: primaryColor,
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: secondaryColor,
      contrastText: "#FFFFFF",
    },
    action: {
      main: "#FFFFFF",
    },
  },
  header: {
    color: primaryColor,
    background: "#FFFFFF",
    search: {
      color: primaryColor,
    },
    indicator: {
      background: primaryColor,
    },
  },
  sidebar: {
    color: "#FFFFFF",
    background: primaryColor,

    header: {
      color: grey[200],
      background: primaryColor,
      brand: {
        color: "#FFFFFF",
      },
    },
    footer: {
      color: grey[200],
      background: primaryColor,
      online: {
        background: green[500],
      },
    },
    category: {
      fontWeight: 500,
    },
    badge: {
      color: "#FFFFFF",
      background: "#FFFFFF",
    },
  },
  body: {
    background: "#e8e8e8",
  },
};

const variants = [evoitVariant];

export default variants;
