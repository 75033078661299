import { SUCCESS } from "../../constants";
import Axios from "axios";
import { getURL, handleErrors } from "../../../utils";

// change password email
export const sendResetPasswordEmail = (email, history) => async (dispatch) => {
  try {
    await Axios.post(`${getURL()}requestresetpassword`, {
      email: email,
    });

    dispatch({
      type: SUCCESS,
      payload: `Email sent to - ${email}`,
    });
    history.push("/");
  } catch (error) {
    // toon foutmelding
    dispatch(handleErrors(error));
  }
};

// change password email
export const resetPassword = (key, password, history) => async (dispatch) => {
  try {
    const response = await Axios.post(`${getURL()}resetpassword`, {
      key: key,
      password: password,
    });

    dispatch({
      type: SUCCESS,
      payload: response.value,
    });
    // redirect naar login pagina
    history.push("/");
  } catch (error) {
    // toon foutmelding
    dispatch(handleErrors(error));
  }
};

export const setPassword = (key, password, history) => async (dispatch) => {
  try {
    const response = await Axios.post(`${getURL()}setpassword`, {
      key: key,
      password: password,
    });

    dispatch({
      type: SUCCESS,
      payload: response.value,
    });
    // redirect naar login pagina
    history.push("/");
  } catch (error) {
    // toon foutmelding
    dispatch(handleErrors(error));
  }
};
