import { t } from "i18next";
import * as yup from "yup";

function getLocale(string) {
  return {
    mixed: {
      // default error bij required gebruikt de path/naam (indicating where the error was thrown) van de component en voeg verplicht toe
      required: ({ path }) =>
        `*${t(`${string}.${path}`)} ${t("loginComponent.input.required")}`,
    },
  };
}

export const getLoginValidationScheme = () => {
  yup.setLocale(getLocale("loginComponent.input"));

  return yup.object({
    email: yup.string().email().required(),
    password: yup.string().required(),
  });
};

export const getResetPasswordValidationScheme = () => {
  yup.setLocale(getLocale("loginComponent.input"));

  return yup.object({
    email: yup.string().email().required(),
  });
};
export const getNewPasswordValidationScheme = () => {
  yup.setLocale(getLocale("loginComponent.input"));

  return yup.object({
    isValidPassword: yup.boolean().oneOf([true], t("error.password")),

    repeatPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], t("error.password_match"))
      .required(),
  });
};

export const getIntegrationValidationScheme = () => {
  yup.setLocale(getLocale("integrationComponent.dialog"));

  return yup.object({
    description: yup.string().required(),
    //   .min(1, t("general:dialog.error.short")),
    application: yup.string().required(),
    //   .min(1, t("general:dialog.error.short")),
    licversionsid: yup.number().required(),
  });
};

export const getUserValidationScheme = () => {
  yup.setLocale(getLocale("userComponent.dialog.input"));

  return yup.object({
    firstname: yup.string().required(),
    userChoice: yup.bool(),
    lastname: yup.string().required(),
    loginemail: yup.string().required().email(),
    expdate: yup.string().when("isTempUser", {
      is: true,
      then: () => yup.string().required(),
      otherwise: () => yup.string().notRequired(),
    }),

    //passwordcheck is uitbesteed aan react-password-checklist. We checken hier dus slechts op of het voldoet of niet
    isValidPassword: yup.string().when("isUserChoice", {
      is: false,
      then: () => yup.boolean().oneOf([true], t("error.password")),
      otherwise: () => yup.boolean().notRequired(),
    }),
  });
};

export const getProfileValidationScheme = () => {
  yup.setLocale(getLocale("profileComponent.dialog.input"));

  return yup.object({
    description: yup.string().required(),
  });
};
